@import "../../scss/variables";

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: $grayLight;
  margin-bottom: 10px;
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    &:not(:last-child) {
      margin-right: 16px;
      &::after {
        content: "";
        width: 16px;
        height: 16px;
        background: url("../../assets/img/breadcrums-arrow.svg") no-repeat center;
        margin-left: 16px;
      }
    }
  }
  &__link {
    color: $gray;
  }
}
