#root {
  .react-date-picker {
    //width: 100%;
    margin-left: auto;
    &__wrapper {
      border: none;
    }
    &__calendar-button {
      padding-right: 0;
    }
    &__inputGroup {
      padding-left: 0;
      display: none;
    }

    &__calendar {

      .react-calendar {
        font-family: $userfont;
        border-radius: 4px;
        border: 2px solid #E6E6E6;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

        &__navigation button:enabled:hover,
        &__navigation button:enabled:focus {
          background: $f8;
        }

        &__month-view__weekdays__weekday {
          abbr {
            text-decoration: none;
            font-weight: normal;
            text-transform: capitalize;
            color: $gray;
            font-size: 14px;
          }
        }

        &__tile {
          &:enabled:hover, &:enabled:focus {
            background: $f8;
          }
          &--now {
            background: $white;
            box-shadow: inset 0 0 0 1px $orange;
          }
          &--hover {
            background: $redLight;
          }
          &--active {
            background: $orange;

            &:enabled:hover, &:enabled:focus {
              background: transparentize($orange, .2);
            }
          }
          &--hasActive {
            background: $orangeLight
          }
          &--rangeStart {
            border-top-left-radius: 18px;
            border-bottom-left-radius: 18px;
          }
          &--rangeEnd {
            border-top-right-radius: 18px;
            border-bottom-right-radius: 18px;
          }
        }
      }
    }
  }
}
