@import "variables";
@import "reset";
@import "fonts";
@import "font";
@import "page";
@import "status";
@import "general";
@import "radio";
@import "form";
@import "overlay";
@import "datepicker";
@import "header";

html.lock,
html.lock body,
body.lock {
	overflow: hidden;
}
body {
	font-family: $userfont;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
	color: $text;
	position: relative;
	background-color: $f8;

	@media (max-width:990px) {
		padding-top: 144px
	}
	@media (max-width:600px) {
		padding-top: 104px
	}
}

#root {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.color--black{
	color: $black;
	fill: $black;
}

.color--white{
	color: $white;
	fill: $white;
}

.color--red {
	color: $red;
	fill: $red;
}
.color--green {
	color: $green;
	fill: $green;
}
.color--gray {
	color: $gray;
	fill: $gray;
}
.color--orange {
	color: $orange;
	fill: $orange;
}
.color--yellow {
	color: $yellow;
	fill: $yellow;
}
.bgcolor--red-light {
	background-color: $redLight;
}

.error {
	color: $red;
	margin-bottom: 10px;
}

.points {
	display: none;
	@media (max-width: 990px) {
		display: block;
	}
}
.staging {
	cursor: pointer;
	align-items: center;
	display: inline-grid;
	grid-auto-flow: column;
	&__icon {
		width: 16px;
		height: 16px;
		margin-left: 8px;
		path {
			fill: $gray;
		}

		&.active path {
			fill: $orange;
		}
	}
	&.active .staging__icon path {
		fill: $orange;
	}
	&.disabled {
		cursor: not-allowed;
	}
}

.result {
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		width: 100%;
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__found {
		display: none;
		@media (max-width: 768px) {
			display: block;
		}
	}

	&__all {
		cursor: pointer;
		font-weight: 500;
		border-bottom: 1px dashed $text;
	}

	&__loaded {
		margin-right: 10px;
	}
}

.option-search {
	display: none;
	@media (max-width: 1200px) {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
}

.message {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: start;
	&__icon {
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}
}

.basket-all {
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		margin-top: 10px;
		justify-content: space-between;
		width: 100%;
	}
	&__list {
		display: flex;
		align-items: center;
		margin-right: 50px;
		@media (max-width: 768px) {
			margin-right: 10px;
		}
	}

	&__icon {
		margin-right: 16px;
		@media (max-width: 768px) {
			margin-right: 10px;
		}
		path {
			fill: $text;
		}
	}

	&__link {
		text-decoration: underline;
		font-weight: 500;
	}

	&__save {
	}
}


//TODO доделать и перенести в репозиторий верстки
.shop-rename {
	display: flex;
	align-items: center;
	height: 100%;
	padding-right: 26px;
	position: relative;
	width: max-content;
	max-width: 200px;
	&:hover .shop-rename__icon {
		display: block;
	}
	&__icon {
		display: none;
		position: absolute;
		right: 0;
		top: 2px;
	}
}

.changed {
	position: relative;
	&:hover .tooltip {
		display: block;
		left: calc(100% + 12px);
        transform: translate(0, -50%);
        bottom: auto;
        top: 50%;
        max-width: 206px;
        &::after {
            left: -8px;
            top: 50%;
            bottom: auto;
            transform: translate(0, -50%);
            border-width: 8px 8px 8px 0;
            border-color: transparent $gray transparent transparent;
        }
	}
}

.crop{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.close {
	cursor: pointer;
	width: 20px;
	height: 20px;
	path{
		fill: $text;
	}
}

.input {
	border: 1px solid $grayLight;
	border-radius: 4px;
	height: 44px;
	padding: 10px 12px;
	width: 100%;
	&.error {
		border: 1px solid $red;
	}
	&:disabled {
		background-color: $f8;
		border: 1px solid #ebebeb;
		cursor: not-allowed;
	}
}

.btn-mobile-full-width {
	width: 100%;

	div, img, svg {
		margin-right: auto;
	}
}

.delete, .edit {
	width: 16px;
	height: 16px;
	transition: all 0.2s ease 0s;
	cursor: pointer;
	fill: $grayLight;
	&:hover {
		fill: $orange;
	}
}

.cause {
	display: flex;
	align-items: center;
	&__text {
	}

	&__icon {
		margin-left: 12px;
	}
}

.container {
	position: relative;
	margin: 0 auto;
	width: 100%;
	padding: 0 32px;
	@media (max-width: 480px) {
		padding: 0 16px;
	}
}

.user-action-flex {
	display: flex;
	justify-content: space-between;

	&>div {
		margin-left: 14px;
	}
}

.frame {
	background-color: #fff;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
	&__form {
		padding: 24px;
	}

}

.shop-rename {
	display: flex;
	align-items: center;
	@media (max-width: 768px) {
		justify-content: space-between;
	}
	&:hover .shop-rename__icon {
		display: block;
	}

	&__icon {
		display: none;
		margin-left: 10px;
		@media (max-width: 768px) {
			display: block;
		}
	}
}

.success {
	width: 32px;
	height: 32px;
}
.denied {
	width: 32px;
	height: 32px;
}

.animationLoad {
	animation: rotate 1s linear infinite;
}

@keyframes rotate {
	from {
		transform: rotate(-360deg);
	}
}

.option-search {
	display: none;
	@media (max-width: 1200px) {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
}

.infinite-list__wrapper {
	position: absolute;
	width: calc(100% - 344px);
	height: 100% !important;
	left: 344px;
	min-height: 550px;

	@media (max-width: 1200px) {
		width: 100%;
		left: 0;
		height: calc(100vh - 437px) !important;
		min-height: auto;
	}

	@media (max-width: 990px) {
		position: relative;
	}

	@media (max-width: 768px) {
		height: auto !important;
	}
}