.title {
  font-weight: 450;
  font-size: 32px;
  line-height: 20px;
  color: #7E8489;
  text-align: center;
  margin: 68px 0;
}

.table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-top: 1px solid #242A35;
  border-bottom: 1px solid #242A35;
  margin-bottom: 130px;

  &__item {
    padding: 48px 52px;
    border-right: 1px solid #242A35;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &:last-child {
      border-right: none;
    }

    img{
      width: 100%;
      height: auto;
      max-width: 56px;
      max-height: 56px;
    }

    p{
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: #242A35;
    }
  }
}