@import "../../../../../scss/variables";

.content {
  background: #E8E8E8;
  padding: 88px 16px 16px;
  text-align: center;
  margin-bottom: 8px;

  h1 {
    font-weight: 400;
    font-size: 68px;
    line-height: 58px;
    color: $black;
    margin-bottom: 38px;
  }

  .description {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    color: $gray;
    margin-bottom: 38px;
  }

  .subtitle {
    font-size: 32px;
    line-height: 20px;
    color: $black;
    margin-bottom: 38px;
  }

  .map img {
    max-width: 100%;
  }
}





