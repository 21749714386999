$black: #242A35;
$blackLight: #313640;
$white: #fff;
$orange: #EE5E20;
$orangeActive: #D64000;
$orangeLight: #FFE0D3;
$text: #313640;
$gray: #7e8489;
$grayLight: #C4C4C4;
$grayLightest: #D4D4D4;
$f8: #f8f8f8;
$red: #FF2B2B;
$redLight: #fdecec;
$green: #219653;
$greenLight: #D0E9DB;
$yellow: #F2C94C;
$yellowLight: #FAEAD2;
$systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif;
$userfont: 'FuturaPT', $systemfont;
$title: 'FuturaFuturisC', $systemfont;

//$darker-color: darken($accent, 5%);
//$lighter-color: lighten($accent, 5%);

/* ::selection{
	background-color: $accent;
	color: #fff;
} */


