@import "../../../../../scss/variables";

.quality {
  background: $blackLight;
  padding: 44px 54px 0;
  min-height: 470px;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 400;
    font-size: 68px;
    line-height: 58px;
    color: $orange;
    margin-bottom: 29px;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1;

    &>div {
      &:first-child {
        color: $orange;
        font-size: 32px;
        line-height: 32px;
        background-image: url("images/detail.png");
        background-repeat: no-repeat;
        background-position: center bottom;
      }

      &:last-child {
        color: $white;
        font-size: 32px;
        line-height: 32px;

        p {
          margin-bottom: 32px;
        }

        ul {
          padding-left: 40px;

          li {
            position: relative;
            margin-bottom: 32px;

            &:before {
              height: 20px;
              width: 20px;
              content: '';
              display: block;
              background-image: url("images/bullet.png");
              background-repeat: no-repeat;
              background-size: contain;
              position: absolute;
              top: 9px;
              left: -40px;
            }
          }
        }
      }
    }
  }
}