@import "../../../../../scss/variables";

.container {
  max-width: 1376px;
  margin: 0 auto;
  padding: 32px;
}

.breadcrumbs {
  padding: 20px 32px 0;
}

.banner {
  position: relative;
  background-color: #E8E8E8;
  background-image: url("images/motor.png");
  background-position: center right;
  background-repeat: no-repeat;
  min-height: 740px;
  display: flex;
  flex-direction: column;

  &:after{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 334px;
    height: 100%;
    min-height: 740px;
    background-color: #F46C32;
    mix-blend-mode: multiply;
  }
}

.wrapper {
  max-width: 1116px;
  margin: auto auto !important;
}

.info {
  display: flex;
  flex-direction: column;
  max-width: 580px;
  top: -40px;
  position: relative;

  &__title {
    font-weight: 400;
    font-size: 68px;
    line-height: 58px;
    color: $black;
    margin-bottom: 39px;
  }
}

.statistics {
  &__title {
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
    color: $black;
    margin-bottom: 69px;
  }

  &__description {
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    color: $blackLight;
  }
}

.textOutline {
  position: relative;
  display: inline-flex;
  color: $black;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 8%;
    left: -1%;
    transform: matrix(1, 0.09, -0.09, 1, 0, 0);
    display: block;
    width: 104%;
    height: 65px;
    border: 2px solid #F46C32;
    border-radius: 50%;
    z-index: -1;
  }
}