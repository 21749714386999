.breadcrumbs {
  margin-top: 20px;
}

.title {
  font-weight: 400;
  font-size: 32px;
  line-height: 20px;
  color: #000000;
  margin: 44px 0 8px;
}

.list {
  margin-bottom: 136px;

  &__tilte {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #262D32;
    margin: 32px 0 24px;
  }

  &__item {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #262D32;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__number{
    margin-right: 23px;
  }
}