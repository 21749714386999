@import "../../../../../scss/variables";

.header {
  margin: 0 0 78px !important;

  .breadcrumbs {
    padding: 20px 0 0;
  }

  h1 {
    font-size: 68px;
    line-height: 58px;
    margin-top: 52px;
    margin-bottom: 32px;
  }

  p {
    font-size: 32px;
    line-height: 32px;
    max-width: 535px;
    font-weight: 500;
  }
}