@import "../../scss/variables";

.citySelect {
  position: relative;
  max-width: 400px;
  flex: 1 1 auto;

  &.active {
    .citySelect__result {
      display: block;
    }
  }

  &__scroller {
    height: 100% !important;
    max-height: 300px;
    position: relative !important;

    &>div {
      position: relative !important;
      min-height: 32px;
    }
  }

  &__result {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #fff;
    padding: 10px 0 10px 12px;
    border: 2px solid #c4c4c4;
    border-top: none;
    z-index: 1;
    @media (max-width: 480px) {
      padding: 16px;
    }
  }

  &_full-width {
    width: 100%;
    max-width: none;
  }

  &__result-item {
    cursor: pointer;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 20px;
    padding: 6px 0;
  }

  &__result-text {
    font-family: $userfont;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.searchResultMin {
  &__inner {
    display: flex;
    .searchCount {
      margin-top: 0;
      position: absolute;
      right: 50px;
    }
    @media (max-width: 990px) {
      flex-direction: column;
      .searchCount {
        margin-top: 32px;
        position: relative;
        right: 0;
      }
    }
  }
  &__list {
    flex: 1 1 auto;
  }

  &__item {
    margin-bottom: 24px;
  }

  &__link {
    display: block;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease 0s;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    @media (min-width: 990px) {
      &:hover {
        color: $orange;
      }
    }
  }

  &__sub {
    padding-left: 16px;
  }
}