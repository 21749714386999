.title {
  font-weight: 450;
  font-size: 32px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #7E8489;
}

.table {
  margin: 28px 0;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 36px 0;
    border-bottom: 1px solid #242A35;

    &:last-child{
      border-bottom: none;
    }
  }

  &__name {
    display: flex;
    gap: 48px;
    align-items: center;
    align-self: flex-start;

    span {
      font-weight: 450;
      font-size: 32px;
      line-height: 32px;
      color: #242A35;
    }


    img {
      width: 100%;
      height: auto;
      max-width: 48px;
      max-height: 48px;
    }
  }

  &__info {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #313640;
    white-space: pre-wrap;

    p {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
