@import "../../../../../scss/variables";

.weHelp {
  padding: 84px 54px 0;
  min-height: 452px;
  display: flex;
  flex-direction: column;
  background-color: $blackLight;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("images/motor.png");
    mix-blend-mode: soft-light;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .4;
  }

  .title {
    font-weight: 400;
    font-size: 68px;
    line-height: 58px;
    color: $orange;
    margin-bottom: 54px;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1;
    grid-column-gap: 40px;

    ul {
      color: $white;
      font-size: 32px;
      line-height: 32px;
      padding-left: 40px;

      li {
        position: relative;
        margin-bottom: 32px;

        &:before {
          height: 20px;
          width: 20px;
          content: '';
          display: block;
          background-image: url("images/bullet.png");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 9px;
          left: -40px;
        }
      }
    }
  }
}