@import "../../../../../scss/variables";

.faq {
  margin-top: 88px;
  margin-bottom: 54px;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;

    &:first-child {
      border-bottom: 1px solid $black;
      padding-bottom: 16px;
      margin-bottom: 36px;
    }
  }

  &__question {
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 48px;
    }

    p {
      font-size: 32px;
      line-height: 32px;
      font-weight: 500;
    }
  }

  &__answer {
    p, ol {
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 24px;
      color: $black;
    }
    ol {
      padding-left: 20px;
      font-weight: 500;

      li {
        margin-bottom: 20px;
      }
    }
  }
}