@import "../../../../../scss/variables";

.buyersAndProviders {
  margin-top: -55px !important;
  margin-bottom: 8px !important;

  &__row {
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: 1fr 1fr;

    &>div {
      padding: 44px 40px 44px 54px;
      min-height: 562px;
      display: flex;
      flex-direction: column;
    }
  }

  p {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 28px;
    max-width: 390px;

    &.buyersAndProviders__title {
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 38px;
    }
  }

  &__buyer {
    color: $black;
    background: #F46C32;
  }

  &__provider {
    color: $white;
    background-color: $blackLight;
    background-image: url("images/detail.png");
    background-position: bottom right;
    background-repeat: no-repeat;
  }

  &__btns {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    padding: 20px 24px;
    border-radius: 4px;
    font-size: 24px;
    line-height: 16px;
    text-decoration: none;
    display: block;
    color: $black;
    background: #E45112;
    position: relative;

    &--black {
      color: $white;
      background: $black;
    }

    &--arrow {
      @keyframes bounceBefore {
        0%, 100% {
          top: -93px;
        }
        50% {
          top: -108px;
        }
      }
      @keyframes bounceAfter {
        0%, 100% {
          top: -125px;
        }
        50% {
          top: -140px;
        }
      }
      &:after,
      &:before {
        content: '';
        position: absolute;
        background: $black;
        pointer-events: none;
      }
      &:after {
        height: 85px;
        width: 2px;
        top: -125px;
        left: calc(50% - 1px);
        animation: bounceAfter 2s infinite cubic-bezier(.71,.01,.32,.99);
      }
      &:before {
        height: 46px;
        width: 46px;
        border: 2px solid $black;
        border-top-color: transparent;
        border-left-color: transparent;
        background: transparent;
        left: calc(50% - 23px);
        transform: rotate(45deg);
        transform-origin: center;
        top: -93px;
        animation: bounceBefore 2s infinite cubic-bezier(.71,.01,.32,.99);
      }
    }
  }
}