@import "../../../../../scss/variables";

.return {
  padding: 70px 0 120px;
  background-color: #F5F5F5;

  h1 {
    text-align: center;
    font-size: 32px;
    line-height: 32px;
    color: $black;
    margin-bottom: 120px;
    font-weight: 500;
  }

  &__stepper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__step {
    display: grid;
    grid-template-areas: 'left number right';
    grid-template-columns: minmax(auto, 434px) 40px minmax(auto, 434px);
    grid-template-rows: 160px;
    grid-column-gap: 45px;
    align-items: center;
    color: $black;

    p {
      font-size: 24px;
      line-height: 28px;
    }

    .gray {
      color: #7E8489;
    }

    &Left {
      grid-area: left;
      text-align: right;
    }

    &Number {
      grid-area: number;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;

      span {
        min-height: 40px;
        width: 40px;
        border-radius: 100px;
        border: 1px solid $black;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:before,
      &:after {
        content: '';
        width: 1px;
        background: $black;
        height: 60px;
        position: absolute;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }

      &--done {
        span {
          background-image: url("images/check.svg");
          background-size: auto;
          background-repeat: no-repeat;
          background-position: center;
          border-color: $orange;
        }
      }
    }

    &Right {
      grid-area: right;
      text-align: left;
    }

    &:first-child {
      .return__stepNumber {
        &:before {
          height: 90px;
          top: -30px;
        }
      }
    }

    &:last-child {
      .return__stepNumber {
        &:after {
          height: 90px;
          bottom: -30px;
        }
      }
    }
  }
}