@import "../../../../../scss/variables";

.advantages {
  padding-top: 88px;

  h1 {
    font-size: 68px;
    line-height: 58px;
    color: $black;
    margin-bottom: 70px;
    text-align: center;
  }

  .grid {
    .row {
      display: grid;
      border-bottom: 1px solid $black;

      &:first-child {
        border-top: 1px solid $black;
      }

      &>div:not(:last-child) {
        border-right: 1px solid $black;
      }

      &--2cols {
        grid-template-columns: 1fr 1fr;
      }

      &--3cols {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .cell {
      padding: 43px 30px 40px 52px;
      display: grid;
      grid-template-areas: 'img' 'descr';
      grid-auto-rows: 56px auto;
      font-size: 24px;
      line-height: 28px;

      &--withLink {
        grid-template-areas: 'img' 'descr' 'link';
        grid-auto-rows: 56px auto 28px;
      }

      &__img {
        grid-area: img;
        height: 56px;
        width: 56px;
      }

      &__descr {
        grid-area: descr;
        margin-top: 53px;
        color: $black;
      }

      &__link {
        grid-area: link;
        color: $orange;
        text-decoration: none;
        font-weight: 500;
        margin-top: 13px;
      }
    }
  }
}